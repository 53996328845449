.button
  display: flex
  width: 100%
  box-sizing: border-box
  padding: 0 16px
  border: none
  border-radius: 8px
  text-decoration: none
  letter-spacing: 0.1px
  font-weight: 500
  height: 40px
  cursor: pointer
  transition: color 0.2s
  user-select: none
  pointer-events: auto
  align-items: center
  justify-content: center
  -webkit-tap-highlight-color: transparent

  &:focus
    outline: none

.primary
  background-color: $green
  color: $button-color

  &:hover
    background-color: $green-hover
    color: $button-color

  &.disabled
    background-color: $grey-hover
    color: #8E8E8E

.secondary
  background-color: transparent
  border: 1px solid $black
  color: $black

  &:hover
    border-color: $green
    color: $green

  &.disabled
    opacity: 0.5

.dangerText
  background-color: transparent
  color: #E65D50

  &:hover
    color: #FF7E73

  &.disabled
    opacity: 0.5

.swap
  background-color: $pastel-red
  color: $black

  &:hover
    background-color: #f9a4c4
    color: $black

  &.disabled
    opacity: 0.5

.text
  font-weight: 400
  background-color: transparent
  border: none
  color: $text-color
  padding: 0

  svg
    transition: fill 0.25s
    fill: $text-color

  &:hover
    color: $green-hover

    svg
      fill: $green-hover

  &.disabled
    color: $grey

    svg
      fill: $grey

    &:hover
      color: $grey

      svg
        fill: $grey

.disabled
  cursor: default
  pointer-events: none
  opacity: 0.5
