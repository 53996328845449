.title
  font-weight: 500

  h1&
    font-size: 36px
  h2&
    font-size: 26px
  h3&
    font-size: 24px
  h4&
    font-size: 20px
  h5&
    font-size: 18px
  h6&
    font-size: 16px

.bold
  font-weight: 500
