.root
    position: fixed
    z-index: 999
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    right: 40px
    bottom: 60px
    background: $green
    border-radius: 50%
    transition: all 200ms ease
    opacity: 0
    visibility: hidden
    &:hover
        cursor: pointer
        background: $green-hover


.rootVisible
    composes: root
    opacity: 1
    visibility: visible


.icon
    width: 18px
    height: 18px
    fill: $black
