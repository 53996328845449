.root
  position: relative

.button.button
  display: inline-flex
  align-items: center
  height: auto
  width: auto
  padding: 0

  &:hover
    color: $black

  svg
    fill: $black

.count
  display: inline-block
  margin-right: 4px
  font-size: 12px
  line-height: 16px

.caption
  display: inline-block
  margin-left: 8px
  font-size: 14px
  line-height: 18p
  border-bottom: 1px dashed rgba(8,8,8,.2)

.toast
  display: none
  position: absolute
  left: -15px
  top: -120px
  width: 224px


@media ($min-desktop)
  .toast
    display: block
