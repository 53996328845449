.inputIconGroup
  position: relative

.toggleVisibility.toggleVisibility
  position: absolute
  right: 8px
  top: 50%
  transform: translateY(-50%)
  width: 32px
  height: 32px
  padding: 4px
  background-color: transparent

.input
  display: inline-flex
  height: 40px
  border: 1px solid #D2D2D2
  outline: none
  width: 100%
  margin: 0
  padding: 0 16px
  align-items: center
  box-sizing: border-box
  border-radius: 8px
  font-size: 14px
  color: $input-color
  transition: border-color 0.1s ease-in, color 0.1s ease-in

// Padding for visibility icon
.passwordInput
  padding-right: 44px

.label
  margin-bottom: 8px

.helpText
  font-size: 12px
  margin-top: 8px
  color: #8e8e8e

.invalid
  .input
    border-color: #E65D50

.disabled
  .input
    border-color: $grey-hover
    color: $grey-color
    background-color: $white

.themeDefault
  &:not(.disabled)
    .input
      &:hover, &:active, &:focus
        border-color: $input-active-color

.themeTransparent
  .input
    border: none
    background-color: transparent

