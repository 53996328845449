.root
  position: fixed
  left: 0
  bottom: 48px
  z-index: $toast-container-z-index
  display: flex
  flex-direction: column-reverse
  width: 100%

.toast.toast
  position: relative
  padding: 16px 46px 16px 16px
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2)
  font-weight: 400
  box-sizing: border-box
  width: 100%

.closeIcon
  position: absolute
  right: 16px
  top: 50%
  transform: translateY(-50%)
  width: 14px
  height: 14px
  stroke: $white
  fill: $black

  &:hover
    stroke: #bbb
    fill: #bbb
    cursor: pointer

// Themes
.primary
  background-color: $green
  color: $black

.danger
  background-color: #E65D50
  color: $black


@media ($min-tablet)

  .root
    width: fit-content
    left: 20px

  .toast.toast
    margin-bottom: 20px
    width: 344px
    border-radius: 8px
    max-width: 344px


@media ($min-desktop)

  .root
    bottom: 0


// Slide Animation
@keyframes slideInLeft
  from
    transform: translate3d(-110%, 0, 0)
    visibility: visible

  to
    transform: translate3d(0, 0, 0)

@keyframes slideOutLeft
  from
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    transform: translate3d(-110%, 0, 0)

.animateSlideInLeft
  animation-name: slideInLeft

.animateSlideOutLeft
  animation-name: slideOutLeft


// Required for auto close
@keyframes Toastify__trackProgress
  from
    transform: scaleX(1)
  to
    transform: scaleX(0)

.toastifyProgressBar
  animation: Toastify__trackProgress linear 1 forwards

