.overlay
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: $modal-overlay-z-index
  background-color: rgba(0, 0, 0, .48)
  transition: opacity .2s
  overflow-x: hidden
  overflow-y: auto
  display: flex
  justify-content: center
  align-items: baseline
  padding: 1rem

.overlayAnimate
  opacity: 0

  &.afterOpen
    opacity: 1

  &.beforeClose
    opacity: 0

.container
  display: flex
  align-items: center
  margin: 0 auto
  min-height: calc(100% - (.5rem * 2))
  pointer-events: none
  outline: none
  transition: transform .2s

.containerAnimate
  transform: scale(0.7)

  &.afterOpen
    transform: scale(1)

  &.beforeClose
    transform: scale(0.7)

.root
  width: 300px
  pointer-events: auto
  padding: 24px
  outline: none
  position: relative
  border-radius: 8px
  background-color: $background-color

.hidden
  opacity: 0

.noScroll
  overflow: hidden

.close
  position: absolute
  cursor: pointer
  right: 16px
  top: 16px

.icon
  width: 22px
  height: 11px

@media ($min-tablet)

  .container
    margin: 1.75rem auto
    min-height: calc(100% - (1.75rem * 2))


@media ($max-tablet)
  .mobileFullScreen

    &.overlay
      padding: 0

    &.container
      width: 100%

    &.root
      width: 100%
      min-height: 100vh
      border-radius: 0
      box-sizing: border-box

@media ($max-desktop)
  .tabletFullScreen

    &.overlay
      padding: 0

    &.container
      width: 100%
      margin: 0

    &.root
      width: 100%
      min-height: 100vh
      border-radius: 0
      box-sizing: border-box
