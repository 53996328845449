.modal
  display: flex
  flex-direction: column
  align-items: center

.form
  width: 100%

.input
  margin-top: 10px

.error
  margin-top: 5px
  color: $red

.submitButton
  margin-top: 19px


.title
  margin-bottom: 16px
